import React from 'react';
import {Link} from "react-router-dom";

const Footer: React.FC = () => {
    return (
        <footer className="bg-[#211e3b] footer-4 footer-wrap">
            <div className="footer-bottom p-5">
                <div className="text-center">
                    <div className="flex justify-around items-center flex-col sm:flex-row">
                        <div className="social-link flex items-center">
                            <img src="/images/logo.png" className={"mr-4"} style={{maxWidth: '100px'}} alt="logo"/>
                            <a className={"text-[#696969] flex flex-col justify-center w-8 h-8 mr-4 bg-[#dae9f9] rounded-full"}
                               href="https://www.linkedin.com/company/adrsta"><i className="fab fa-linkedin"></i></a>
                            <a className={"text-[#696969] flex flex-col justify-center w-8 h-8 mr-8 bg-[#dae9f9] rounded-full"}
                               href="https://twitter.com/adrstaai?s=11"><i className="fab fa-twitter"></i></a>
                        </div>
                        <div className="text-secondary text-lg mt-4 sm:mt-0">
                            © 2023 <a className={"text-gray-400"} href="/">Adrsta AI</a>. All Rights Reserved
                        </div>
                        <div className="flex text-gray-400 text-sm my-4 sm:my-0">
                            <Link to={"/privacy"}>Privacy Policy</Link>
                            <Link className={"ml-8"} to={"/terms-and-condition"}>Terms and Conditions</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
