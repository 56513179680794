import React from "react";
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import SolutionCard, { SolutionCardProps } from "./components/SolutionCard";

const solutionList: SolutionCardProps[] = [
  {
    title: "Marketing Mix Model",
    imgSrc: "/images/5.png",
    useCases: [
      "Machine learning model using aggregate data to predict incremental impact of different channels historically and make predictions about different, possible scenarios.",
    ],
  },
  {
    title: "Bid Optimizer",
    imgSrc: "/images/13.png",
    useCases: [
      "In-flight, in-platform optimizations based on machine learning analysis of customer incremental value derived from consented 1P data.",
    ],
  },
  {
    title: "Geo-Measurement",
    imgSrc: "/images/11.png",
    useCases: [
      "Solution to conduct x-channel incremental lift experiments with aggregated data and synthetic controls.",
    ],
  },
  {
    title: "Multi-touch Attribution",
    imgSrc: "/images/15.png",
    useCases: [
      "Digital-only solution for attributing publisher and channel-level contributions to a conversion. AI + aggregated analyses help fill data gaps.",
    ],
  },
];

const Solutions: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="mx-4 sm:mx-32 mt-16 text-xl text-left font-medium text-gray-600 leading-10">With a unique blend of ML, first-party data metrics, privacy-enhancing technology, APIs, and proprietary IP, Adrsta's software enables enhanced media buying and measurement—improving marketing performance by up to 30%.</div>
      <div className="mx-4 sm:mx-32 mb-16 mt-8 text-sl text-left font-medium text-gray-600 leading-10">We are not simply an AdTech company, but rather as a data-science/ML software company that supercharges advertisers' first-party data (from CDPs and walled gardens) to enable data-driven bid optimization and incrementality measurement.</div>
      <div className={"text-center font-extrabold text-4xl text-gray-600 mt-8"}>
        Use Cases
      </div>
      <div className={"flex flex-wrap justify-center"}>
        {solutionList.map((solution, index) => (
          <div key={index} className={"m-12"}>
            <SolutionCard
              imgSrc={solution.imgSrc}
              title={solution.title}
              useCases={solution.useCases}
            />
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Solutions;
