import { Carousel } from "react-responsive-carousel";
import { addSyntheticLeadingComment } from "typescript";
import Button from "./components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";

const AdsPage: React.FC = () => {
    return (
        <div className={"flex flex-col items-center h-screen"}>
            <div
                className={"flex sm:flex-row flex-col items-center justify-center py-2 sm:py-4 bg-primary px-4 sm:px-16 h-screen"}>
                <div className={"flex flex-col justify-center sm:text-4xl text-2xl font-extrabold"}>
                    <div className={"text-white"}>AI-powered No-Code</div>
                    <div className={"my-4 block sm:hidden"}>
                        <Carousel width={"350px"} dynamicHeight={true} showArrows={false} showIndicators={false}
                            showThumbs={false}
                            infiniteLoop={true}
                            showStatus={false}
                            autoPlay={true}
                            interval={2000}>
                            {["Marketing Science", "Media Planning", "Media Activation", "Measurement"].map((item, index) => (
                                <div key={index} className="text-left carousel-item text-secondary py-2">
                                    {item}
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className={" my-4 hidden sm:block"}>
                        <Carousel width={"650px"} dynamicHeight={true} showArrows={false} showIndicators={false}
                            showThumbs={false}
                            infiniteLoop={true}
                            showStatus={false}
                            autoPlay={true}
                            interval={2000}>
                            {["Marketing Science", "Media Planning", "Media Activation", "Measurement"].map((item, index) => (
                                <div key={index} className="text-left carousel-item text-secondary py-2">
                                    {item}
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className={"text-white"}>SaaS for the Cookieless world</div>
                    <div className={"mt-16 bg-black rounded-lg text-white p-4 w-2/3 text-center hover:cursor-pointer"}>
                        <div className={"flex items-center text-lg font-normal"}>
                            <div>
                                Schedule Your Personalized Demo with one of our Data Experts Now!
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"sm:w-3/5 w-full mx-8 my-8"}>
                    <video autoPlay loop muted controls>
                        <source src="/videos/demo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
}

export default AdsPage;
