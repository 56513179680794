import React from 'react';

export interface CardProps {
    imgSrc: string;
    title: string;
    subtitle: string;
}

const Card: React.FC<CardProps> = ({imgSrc, title, subtitle}) => {
    return (
        <>
            <div className={"flex flex-col items-center text-center"} style={{maxWidth: '180px'}}>
                <div className={"flex items-center"} style={{width: '180px', height: '180px'}}>
                    <img className={""} src={imgSrc} alt={""}></img>
                </div>
                <div className={"font-extrabold text-gray-700 text-2xl mt-8"}>{title}</div>
                <div className={"mt-4 text-gray-500 leading-8"}>{subtitle}</div>
            </div>
        </>
    );
};

export default Card;
