import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

const carouselItems = [
    "A Sports company’s needed to increase ticket price using ‘Next Best Action’ model",
    "DTC CMO needed to build ‘Customer Churn’ model",
    "Bank CMO needed to understand the impact of COVID on credit card sales, and media budget allocation",
    "Telecom Director needed a ‘Cross-sell Recommendation’ engine with no CRM data",
    "Agency CTO needed a unified view Facebook and Google Ads performance",
    "An AdTech platform needed to integrate Facebook’s auction APIs"
];

const backgroundColors = ['#1375c2', '#70ad47', '#1f3864', '#ed7d31', '#000000', '#843c0b'];

const UseCases: React.FC = () => {
    return (
        <>
            <Navbar/>
            <div className={"text-center text-secondary text-6xl font-extrabold my-16"}>Adrsta AI Platform</div>
            <div className={"sm:mx-48 mx-4"}>
                <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} interval={2000}>
                    {carouselItems.map((item, index) => (
                        <div key={index} className="carousel-item" style={{backgroundColor: backgroundColors[index]}}>
                            <div
                                className="flex flex-col justify-center text-white text-6xl font-black mx-8"
                                style={{lineHeight: '100px', minHeight: '700px'}}>
                                {item}
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <Footer/>
        </>
    );
};

export default UseCases;
