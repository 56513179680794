import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import {Disc} from "react-feather";

const privacyData = [
    {
        question: "What information do we collect?",
        answer: "We collect the following types of personal information from you",
        bullets: [
            "Contact information: This includes your name and email address",
            "Usage information: This includes information about how you use our website, such as the pages you visit and the products you purchase",
            "Technical information: This includes information about your device, such as your IP address and browser type"
        ]
    },
    {
        question: "How do we use your information?",
        answer: "We use your personal information for the following purposes",
        bullets: [
            "To provide you with our services: We use your contact information to send you order confirmations, invoices, and other important information about our services. We use your financial information to process your payments and to prevent fraud. We use your usage information to improve our website and to provide you with more relevant content. We use your technical information to troubleshoot problems with our website and to protect our systems from unauthorized access",
            "To communicate with you: We use your contact information to send you marketing emails about our products and services. You can unsubscribe from any email",
            "To comply with the law: We may also use your personal information to comply with the law, such as to respond to a subpoena or to prevent fraud"
        ]
    },
    {
        question: "Who do we share your information with?",
        answer: "We may share your personal information with the following third parties",
        bullets: [
            "Our payment processors: We use third-party payment processors to process your payments. These processors may have access to your financial information, such as your credit card number",
            "Our marketing partners: We may share your contact information with our marketing partners. These partners may use your information to send you marketing emails about their products and services",
            "Our legal and regulatory advisors: We may share your personal information with our legal and regulatory advisors if we are required to do so by law or to protect our rights"
        ]
    },
    {
        question: "Your rights",
        answer: "You have the following rights with respect to your personal information",
        bullets: [
            "The right to access: You have the right to request a copy of your personal information",
            "The right to correct: You have the right to correct any inaccuracies in your personal information",
            "The right to delete: You have the right to request that we delete your personal information",
            "The right to object: You have the right to object to our use of your personal information",
            "The right to withdraw consent: You have the right to withdraw your consent to our use of your personal information"
        ]
    },
    {
        question: "Our Policy on Children",
        answer: "Our Site, Application (s) and Services is/are not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us using the details in Section 18 below. We will delete such information from our files as soon as reasonably practicable",
        bullets: []
    },
    {
        question: "International Data Transfer",
        answer: "Your information, including personal data that we collect from you, may be transferred to, stored at, and processed by us and our affiliates and other third parties outside the country in which you reside, including, but not limited to the United States, where data protection and privacy regulations may not offer the same level of protection as in other parts of the world. By using our Site, Application(s), or Services, and providing your consent, you agree to this transfer, storing, or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy",
        bullets: []
    },
    {
        question: "Sensitive Personal Data",
        answer: "Subject to the following paragraph, we ask that you not send us, and you not disclose, any sensitive personal data (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through our Site, Application(s) or Services, or otherwise to us. If you send or disclose any sensitive personal data to us, you consent to our processing and use of such sensitive personal data in accordance with this Policy. If you do not consent to our processing and use of such sensitive personal data, you must not submit such user-generated content to our Site, Application(s), or Services",
        bullets: []
    },
    {
        question: "Retention",
        answer: "We will only retain your personal data as long as reasonably required for you to use the Site, Application(s), and/or to provide you with the Services unless a longer retention period is required or permitted by law (for example for regulatory purposes)",
        bullets: []
    },
    {
        question: "How to contact us",
        answer: "If you have any questions about this privacy policy or about your personal information, please contact us at: support@adrsta.ai",
        bullets: []
    },
    {
        question: "Changes to this privacy policy",
        answer: "We may update this privacy policy from time to time. We reserve the right to modify this Privacy Policy at any time. The most current version of the privacy policy will always be posted on our website.\n" +
            "Effective Date - This Policy is effective as of July 25, 2023",
        bullets: []
    }
]

const Privacy: React.FC = () => {
    return (
        <>
            <Navbar/>
            <div className={"mx-32 my-16 text-gray-600 text-lg"}>This privacy policy applies to the website of Adrsta AI
                Inc. We
                are committed
                to
                protecting your
                privacy and security. This policy explains how we collect, use, and share your personal information.
            </div>
            <div className={"mx-32 mb-16"}>
                {privacyData.map((items, index) => (
                    <div key={index} className={"mt-8"}>
                        <div className={"text-secondary text-2xl font-bold my-2"}>{index + 1}. {items.question}</div>
                        <div className={"text-gray-600 text-lg mb-6"} style={{lineHeight: '36px'}}>{items.answer}</div>
                        {items.bullets.map((point, index) => (
                            <div key={index} className={"text-gray-600 text-lg mt-4 ml-4 flex"}
                                 style={{lineHeight: '24px'}}>
                                <div style={{width: '12px', marginRight: '8px', marginTop: '4px'}}><Disc size={"12"}/>
                                </div>
                                <div>{point}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <Footer/>
        </>
    );
};

export default Privacy;
