import React, {MouseEventHandler, ReactNode} from 'react';

interface ButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
}

const Button: React.FC<ButtonProps> = ({onClick, children}) => {
    return (
        <button onClick={onClick}
                className="bg-primary text-secondary px-8 py-2 rounded-lg  hover:bg-black hover:text-white w-full">
            {children}
        </button>
    );
};

export default Button;
