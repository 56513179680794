import React from 'react';
import { Disc } from "react-feather";

export interface TeamProps {
    name: string;
    imgSrc: string;
    position: string;
    linkedIn: string;
    experience: string[];
    speciality: string[];
    education: string[];
}

const TeamCard: React.FC<TeamProps> = ({ name, position, imgSrc, linkedIn, experience, speciality, education }) => {
    return (
        <div>
            <div className={"flex sm:flex-row flex-col"}>
                <div className={"border-2 border-gray-200 p-4 rounded-2xl"}
                    style={{ height: '400px', borderWidth: '1px' }}>
                    <div className={"flex flex-col items-center"}>
                        <div className={"rounded-lg overflow-hidden"} style={{ width: '210px', height: '210px' }}>
                            <img className={""} src={imgSrc} alt={""} style={{ width: '210px', height: '210px' }} />
                        </div>
                        <div>
                            <h4 className={"text-center text-secondary text-xl font-bold mt-4"}>{name}</h4>
                            <p className={"text-center text-secondary font-lg mt-2"}>{position}</p>
                        </div>
                        <div className={"flex justify-center mt-4 bg-blue-100 items-center rounded-full"}
                            style={{ width: '48px', height: '48px' }}>
                            <a href={linkedIn}
                                target="_blank" rel="noreferrer"><i className="fab fa-linkedin bg-[#dae9f9]"></i></a>
                        </div>
                    </div>
                </div>
                <div className={"ml-8 mt-4"} style={{ width: '300px' }}>
                    <ul>
                        <h5 className={"text-secondary font-bold mb-4"}>Experience</h5>
                        {experience.map((exp, index) => (
                            <li className={"text-xs mt-2 text-gray-500 flex"} key={index}>
                                <div style={{ width: '12px', marginRight: '4px' }}><Disc size={"12"} /></div>
                                {exp}
                            </li>
                        ))}
                        {/* <h5 className={"text-secondary font-bold my-4"}>Specialties</h5>
                        {speciality.map((spe, index) => (
                            <li className={"text-xs mt-2 text-gray-500 flex items-center"} key={index}>
                                <div style={{width: '12px', marginRight: '4px'}}><Disc size={"12"}/></div>
                                {spe}
                            </li>
                        ))} */}
                        {/* <h5 className={"text-secondary font-bold my-4"}>Education</h5>
                        {education.map((edu, index) => (
                            <li className={"text-xs mt-2 text-gray-500 flex items-center"} key={index}>
                                <div style={{width: '12px', marginRight: '4px'}}><Disc size={"12"}/></div>
                                {edu}
                            </li>
                        ))} */}
                    </ul>
                </div>

            </div>

        </div>
    );
};

export default TeamCard;
