import React from "react";
import Navbar from "./components/NavBar";
import Card, { CardProps } from "./components/Card";
import StakeHolderCard, {
  StakeHolderCardProps,
} from "./components/StakeHolderCard";
import Footer from "./components/Footer";
import { Carousel } from "react-responsive-carousel";

const cardList: CardProps[] = [
  {
    title: "User Imperative",
    subtitle: "Build User Trust in Advertising",
    imgSrc: "/images/16.png",
  },
  {
    title: "Regulatory Necessity",
    subtitle: "Help organization remain complaint",
    imgSrc: "/images/14.png",
  },
  {
    title: "Strategic Moment",
    subtitle: "Move faster to beat the competition",
    imgSrc: "/images/15.png",
  },
  {
    title: "Technical Differentiator",
    subtitle: "Disrupt analytics services with AI & automation",
    imgSrc: "/images/18.png",
  },
];

const stakeHolderCardList: StakeHolderCardProps[] = [
  {
    title: "Advertisers",
    subtitle: "Increase return on ad spend",
    imgSrc: "/images/5.png",
    description: "Leverage our software to enhance marketing performance by up-to 30%",
  },
  {
    title: "Agencies & Publishers",
    subtitle: "Differentiate your tech stack",
    imgSrc: "/images/6.png",
    description:
      "Embed our software in your tech stack to serve your customers.",
  },
];

const Home: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className={"flex flex-col items-center"}>
        <div
          className={
            "w-full py-12 flex sm:flex-row flex-col items-center sm:py-4 bg-primary px-4 sm:px-32"
          }
        >
          <div
            className={
              "my-16 flex flex-col justify-center sm:text-4xl text-2xl font-extrabold"
            }
          >
            <div className={"text-white"}>AI-powered No-Code</div>
            <div className={"my-4 block sm:hidden"}>
              <Carousel
                width={"350px"}
                dynamicHeight={true}
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                infiniteLoop={true}
                showStatus={false}
                autoPlay={true}
                interval={2000}
              >
                {[
                  "Marketing Science", "Media Planning", "Media Activation", "Measurement",
                ].map((item, index) => (
                  <div
                    key={index}
                    className="text-left carousel-item text-secondary py-2"
                  >
                    {item}
                  </div>
                ))}
              </Carousel>
            </div>
            <div className={" my-4 hidden sm:block"}>
              <Carousel
                width={"650px"}
                dynamicHeight={true}
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                infiniteLoop={true}
                showStatus={false}
                autoPlay={true}
                interval={2000}
              >
                {[
                  "Marketing Science", "Media Planning", "Media Activation", "Measurement"
                ].map((item, index) => (
                  <div
                    key={index}
                    className="text-left carousel-item text-secondary py-2"
                  >
                    {item}
                  </div>
                ))}
              </Carousel>
            </div>
            <div className={"text-white"}>SaaS for the Cookieless world</div>
          </div>
          {/* <div className={"sm:w-3/5 w-full mx-8 my-8"}>
            <video autoPlay loop muted controls>
              <source src="/videos/demo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div> */}
        </div>
        <div className={"bg-gray-100 text-white w-full overflow-auto"}>
          <div
            className={"flex items-center p-4 font-extrabold text-2xl"}
            style={{ minWidth: "1400px" }}
          >
            <div className={"text-sm text-secondary"}>
              <div>Adrsta AI</div>
              Differentiators
            </div>
            {[
              "Proprietary ML IP for Media Buying and Measurement.",
              "Built on Advertiser First-party data and metrics.",
              "Omnichannel platform resilient to Signal-loss.",
              "Designed with Privacy-in-mind.",
              "Uniquely designed for walled-garden like Meta, Google etc.",
            ].map((item, index) => (
              <div
                key={index}
                className="flex flex-1 items-start text-sm text-black"
              >
                <div
                  className={
                    "border-r-[1px] text-secondary flex items-start text-2xl font-extrabold pr-2 ml-4 mr-2 border-secondary h-12"
                  }
                >
                  {index + 1}
                </div>
                {item}
              </div>
            ))}
          </div>
        </div>
        <div
          className={"font-extrabold text-center mt-16 text-3xl text-gray-600"}
        >
          What motivated us to build Adrsta AI?
        </div>
        <div
          className={
            "max-w-4xl text-gray-500 mt-6 text-lg font-bold text-center leading-8 p-4"
          }
        >
          Societal consciousness of user data privacy has reached an inflection point after the last 20 years of bottomless data hoarding. Privacy laws and technology platforms are just beginning to catch up to users' demands for greater data transparency, control, and autonomy. Continuing signal loss is disrupting the entire AdTech stack that was built over the last two decades. AI and automation are ready to disrupt traditional 'rinse and repeat' marketing data science services.
        </div>
      </div>
      <div className={"flex justify-between m-32 flex-col sm:flex-row"}>
        {cardList.map((card, index) => (
          <div className={"mt-12"} key={index}>
            <Card
              imgSrc={card.imgSrc}
              title={card.title}
              subtitle={card.subtitle}
            />
          </div>
        ))}
      </div>
      <div className={"flex flex-col items-center my-32"}>
        <div className={"font-extrabold text-3xl text-gray-600"}>
          4Ps of Ad-Tech
        </div>
        <div
          className={"text-primary mt-8 text-lg text-center"}
          style={{ maxWidth: "750px" }}
        >
          Adrsta's AI-powered solutions and capabilities prevent data leakage while ensuring the smooth operation of the advertising ecosystem.
        </div>
        <div className={"mx-4 sm:mx-32"}>
          <img className={"mt-16 "} src={"/images/2.png"} alt={""} />
        </div>
      </div>
      <div className={" my-48"} style={{ width: "100%" }}>
        <div className={"font-extrabold text-3xl text-gray-600 text-center"}>
          By Stakeholders
        </div>
        <div className={"flex justify-around mt-16 mx-32 sm:flex-row flex-col"}>
          {stakeHolderCardList.map((card, index) => (
            <StakeHolderCard
              imgSrc={card.imgSrc}
              title={card.title}
              subtitle={card.subtitle}
              description={card.description}
            />
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
