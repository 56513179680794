import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import {Disc} from "react-feather";

const termConditionData = [
    {
        question: "Introduction",
        answer: "These Terms of Service (the \"Terms\") govern your use of the website located at www.adrsta.ai (the \"Website\"). The Website is owned and operated by adrsa Inc. (the “Company”)",
        bullets: []
    },
    {
        question: "Acceptance of Terms",
        answer: "By using the Website, you agree to be bound by these Terms in full. If you do not agree to these Terms, you may not use the Website",
        bullets: []
    },
    {
        question: "Changes to Terms",
        answer: "The Company may modify these Terms at any time. The most current version of the Terms will be posted on the Website. You should review the Terms periodically to ensure that you are aware of any changes. Your continued use of the Website after any changes to the Terms constitutes your acceptance of the modified Terms",
        bullets: []
    },
    {
        question: "Use of Website",
        answer: "The Website is provided for your personal, non-commercial use. You may not use the Website for any commercial purpose. You may not use the Website to:",
        bullets: [
            "Upload, post, or transmit any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, or otherwise objectionable",
            "Upload, post, or transmit any content that infringes the intellectual property rights of others",
            "Upload, post, or transmit any content that is harmful to minors",
            "Impersonate any person or entity",
            "Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content",
            "Collect or store personal information about other users",
            "Use the Website in any way that violates any applicable law or regulation. The Company reserves the right to remove any content from the Website at any time, for any reason",
        ]
    },
    {
        question: "Links to Third-Party Sites",
        answer: "The Website may contain links to third-party websites. These links are provided for your convenience only. The Company does not control these third-party websites and is not responsible for their content",
        bullets: []
    },
    {
        question: "Disclaimer of Warranties",
        answer: "The Website is provided on an \"as is\" and \"as available\" basis. The Company makes no representations or warranties of any kind, express or implied, with respect to the Website, including but not limited to the accuracy, completeness, or timeliness of the content",
        bullets: []
    },
    {
        question: "Limitation of Liability",
        answer: "In no event shall the Company be liable to you or any third party for any direct, indirect, incidental, consequential, special, or punitive damages arising out of or in connection with your use of the Website, including but not limited to lost profits, lost revenue, lost savings, loss of data, or any other pecuniary loss, even if the Company has been advised of the possibility of such damages",
        bullets: []
    },
    {
        question: "Indemnification",
        answer: "You agree to indemnify and hold the Company harmless from and against any and all claims, losses, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Website, including but not limited to your violation of these Terms",
        bullets: []
    },
    {
        question: "Governing Law",
        answer: "The laws of the Country, excluding its conflicts of law rules, shall govern these Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws",
        bullets: []
    },
    {
        question: "Entire Agreement",
        answer: "These Terms constitute the entire agreement between you and the Company with respect to the use of the Website. These Terms supersede all prior or contemporaneous communications, representations, or agreements, whether oral or written",
        bullets: []
    },
    {
        question: "Severability",
        answer: "If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck from these Terms and the remaining provisions shall remain in full force and effect",
        bullets: []
    },
    {
        question: "Waiver",
        answer: "No waiver of any provision of these Terms shall be effective unless in writing and signed by both parties",
        bullets: []
    },
    {
        question: "Contact Information",
        answer: "If you have any questions about these Terms, please contact Company at: support@adrsta.ai",
        bullets: []
    },
    {
        question: "Changes to these terms",
        answer: "The Company reserves the right to change, modify, or update these terms and conditions of use, at any time without notice. The date of the latest update is presented below\n" +
            "Effective Date - Effective as of July 25, 2023",
        bullets: []
    }
]

const TermConditions: React.FC = () => {
    return (
        <>
            <Navbar/>
            <div className={"mx-32 mb-16"}>
                {termConditionData.map((items, index) => (
                    <div key={index} className={"mt-8"}>
                        <div className={"text-secondary text-2xl font-bold my-2"}>{index + 1}. {items.question}</div>
                        <div className={"text-gray-600 text-lg mb-6"} style={{lineHeight: '36px'}}>{items.answer}</div>
                        {items.bullets.map((point, index) => (
                            <div key={index} className={"text-gray-600 text-lg mt-4 ml-4 flex"}
                                 style={{lineHeight: '24px'}}>
                                <div style={{width: '12px', marginRight: '8px', marginTop: '4px'}}><Disc size={"12"}/>
                                </div>
                                <div>{point}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <Footer/>
        </>
    );
};

export default TermConditions;
