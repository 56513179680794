import React from "react";
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import PricingCard, { PricingProps } from "./components/PricingCard";

const pricingItems: PricingProps[] = [
  {
    planDescription: "Discover how Adrsta AI can help you for free",
    planName: "Starter",
    price: "Free",
    planOneLiner: "Audit reports, demo & recommendations",
    everythingText: "",
    planFeatures: [
      "Marketing Mix Model",
      "AdTech. Audit",
      "MarTech. Audit Report",
      "Platform Demo",
      "Recommendation on which MarTech, AdTech, Data Science, ML models are useful for your business",
    ],
  },
  {
    planDescription:
      "Ideal for companies that are starting to use data and AI for Marketing & Ads",
    planName: "Pro",
    price: "$ 4,500/mo",
    everythingText: "Everything in Starter, plus",
    planOneLiner: "SaaS platform",
    planFeatures: [
      "Contextual Targeting",
      "Bid Optimizer",
      "Next Best Action",
      "Marketing Mix Model",
      "Multi-touch Attribution Model",
    ],
  },
  {
    planDescription:
      "Ideal for companies with limited marketing science, analytics and engineering support",
    planName: "Expert",
    price: "$ 8,500/mo",
    pricingSuffix: "(starting at)",
    everythingText: "Everything in Pro, plus",
    planOneLiner: "SaaS platform + Dedicated Data Scientist/Business Engineer",
    planFeatures: [
      "Customer Journey Model",
      "Look-a-Like Audiences",
      "Custom (Geo etc.) Targeting",
      "Customer Lifetime Value",
      "RFM (Recency, Frequency, Monetary) Model",
      "Churn Model",
      "Incrementality platform",
      "Geo-Lift Study",
    ],
  },
  {
    planDescription:
      "Ideal for companies that want to scale up Marketing, Advertising, Data science and Engineering operations",
    planName: "Premium",
    price: "Custom Pricing",
    everythingText: "Everything in Expert, plus",
    planOneLiner:
      "SaaS platform + Marketing Science & Business Engineering Team",
    planFeatures: [
      "Brand Safety Study",
      "Brand Suitability Study",
      "Creative Full-Funnel Study",
      "Sentiment Analysis",
      "Clean-room Analytics",
      "Conversion API",
      "Topics API",
      "Synthetic Data",
      "Data Quality",
      "Google, Meta, Amazon and Shopify API integrations",
      "Custom integrations with 3rd parties",
    ],
  },
];

const Pricing: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className={"text-center py-12 text-3xl font-bold"}>
        Find the plan that works best for you
      </div>
      <div
        className={
          "flex sm:flex-row flex-col justify-center items-center sm:items-start mb-24 mx-4 sm:mx-48"
        }
      >
        {pricingItems.map((pricingItem, index) => (
          <div className={"sm:mr-8 sm:mt-8"}>
            <PricingCard
              planName={pricingItem.planName}
              planDescription={pricingItem.planDescription}
              planOneLiner={pricingItem.planOneLiner}
              price={pricingItem.price}
              planFeatures={pricingItem.planFeatures}
              pricingSuffix={pricingItem.pricingSuffix}
              everythingText={pricingItem.everythingText}
            />
          </div>
        ))}
      </div>
      <div>
        <div className="content-area">
          <section className="about-us-wrapper mb-12">
            <div className="sm:mx-20 mx-4">
              <div className="competition-container flex sm:flex-row flex-col justify-center">
                <div className="left-section bg-[#262626] text-white text-center p-8">
                  <div className="text-4xl font-bold">
                    Competitive Landscape
                  </div>
                  <div className="question font-bold mt-12 text-2xl">
                    Why{" "}
                    <span className={"text-2xl font-extrabold"}>adrsta</span> is
                    unique?
                  </div>
                  <div className="answers mt-16 text-left">
                    <ul>
                      <li className={"mt-5"}>
                        &#10003; Marketing Science SaaS in a box for CMOs
                      </li>
                      <li className={"mt-5"}>
                        &#10003; Bridges MarTech. & AdTech. Technologies
                      </li>
                      <li className={"mt-5"}>
                        &#10003; Comprehensive ML & AI models for MarTech. &
                        AdTech use-cases
                      </li>
                      <li className={"mt-5"}>
                        &#10003; Made for Cookie-less world
                      </li>
                      <li className={"mt-5"}>
                        &#10003; Designed with Privacy-in-mind
                      </li>
                      <li className={"mt-5"}>
                        &#10003; Built on Meta & Google APIs
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="right-section border-2 border-black p-6">
                  <div className="quadrant-container h-full relative">
                    <div className="row-one flex" style={{ height: "350px" }}>
                      <div
                        className="quad-1 rounded-full flex flex-col justify-center"
                        style={{ border: "1px solid #f1c40f", width: "350px" }}
                      >
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#3F3F3F" }}
                        >
                          In house
                        </div>
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#3F3F3F" }}
                        >
                          Marketing Research, Science & Analytics services
                        </div>
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#C00000" }}
                        >
                          Lack of capability, No Software
                        </div>
                      </div>
                      <div
                        className="quad-2 rounded-full flex flex-col justify-center items-center"
                        style={{ border: "1px solid #e74c3c", width: "350px" }}
                      >
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#3F3F3F" }}
                        >
                          3rd party
                        </div>
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#3F3F3F" }}
                        >
                          Marketing Research, Science & Analytics services
                          company
                        </div>
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#C00000" }}
                        >
                          Pure Services, No Software
                        </div>
                      </div>
                    </div>
                    <div className="row-two flex" style={{ height: "350px" }}>
                      <div
                        className="quad-3 rounded-full flex flex-col justify-center"
                        style={{ border: "1px solid #2ecc71", width: "350px" }}
                      >
                        <div
                          className={"sm:text-xl text-sm font-bold text-center"}
                          style={{ color: "#3F3F3F" }}
                        >
                          Measurement SaaS
                        </div>
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#C00000" }}
                        >
                          Too Narrow
                        </div>
                      </div>
                      <div
                        className="quad-4 rounded-full flex flex-col justify-center"
                        style={{ border: "1px solid #3498db", width: "350px" }}
                      >
                        <div
                          className={"sm:text-xl text-sm font-bold text-center"}
                          style={{ color: "#3F3F3F" }}
                        >
                          CDP Providers
                        </div>
                        <div
                          className={
                            "sm:text-xl text-sm font-bold text-center mb-5"
                          }
                          style={{ color: "#C00000" }}
                        >
                          Rigid, Lack of customization
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "sm:top-[35%] top-[35%] sm:left-[35%] left-[20%] absolute flex flex-col items-center justify-center bg-[#ffd555] rounded-full p-10 z-20"
                      }
                      style={{ width: "220px", height: "220px" }}
                    >
                      <img src="/images/logo.png" alt="adrsta" />
                      <div className={"text-center text-3xl"}>adrsta</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
