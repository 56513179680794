import React from 'react';

export interface StakeHolderCardProps {
    imgSrc: string;
    title: string;
    subtitle: string;
    description: string;
}

const StakeHolderCard: React.FC<StakeHolderCardProps> = ({imgSrc, title, subtitle, description}) => {
    return (
        <>
            <div className={"flex flex-col items-center text-center"} style={{maxWidth: '280px'}}>
                <div className={"flex items-center"} style={{width: '200px', height: '200px'}}>
                    <img className={""} src={imgSrc} alt={""}></img>
                </div>
                <div className={"font-extrabold text-gray-700 text-2xl mt-8"}>{title}</div>
                <div className={"mt-4 text-gray-700 leading-8 text-lg"}>{subtitle}</div>
                <div className={"mt-4 text-gray-700 leading-8 text-sm"}>{description}</div>
            </div>
        </>
    );
};

export default StakeHolderCard;
