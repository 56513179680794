import React from 'react';
import {Calendar} from 'react-feather';
import {Link} from "react-router-dom";

const bulletPoints = [
    "Direct access to Adrsta AI Data Science and Engineering team",
    "Dedicated support to understand Adrsta AI’s impact on the health and performance of your Meta investment",
    "Dedicated onboarding to data science models for your analysts and planners",
    "Partner pricing",
    "Skip the waitlist"
];

const METAAGP: React.FC = () => {
    return (
        <>
            <div className={"sm:w-4/5 w-full m-auto h-lvh"}>
                <div className={"flex sm:flex-row flex-col justify-center"}>
                    <div className={"left-section sm:w-2/3 w-full p-12"}>
                        <div className={"flex flex-col items-center"}>
                            <img width={'120px'} src="/images/logo.png" alt="adrsta"/>
                            <div className={"text-secondary font-bold text-2xl"}>Adrsta AI</div>
                        </div>
                        <div className={"text-3xl font-bold mt-8 text-center"}>Sign up to access your free MMM report
                            and
                            consultation
                            today!
                        </div>
                        <div className={"mt-8"}>We are excited to partner with Meta to offer Agency Growth Program
                            participants
                            early access to
                            Adrsta AI — our proprietary marketing science copilot to help you drive performance and
                            personalization for your clients in the post-cookie landscape. Sign up today to receive your
                            first, fast MMM report for free, as well as a free Data Science and Engineering
                            consultation,
                            with no obligation to continue. If you decide to sign up for Adrsta AI, you will also
                            receive
                            these additional, exclusive benefits for Agency Growth Program participants:
                        </div>
                        <div className={"bullet-points mt-8"}>
                            <ul className={"flex flex-wrap justify-end"}>
                                {bulletPoints.map((point, index) => (
                                    <li key={index}
                                        className={"sm:w-1/2 w-full mt-4 pr-4"}>
                                        <span
                                            className={"font-extrabold text-xl text-black mr-2"}>&#10003;</span> {point}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div
                        className={"right-section sm:w-1/3 w-full bg-secondary flex flex-col justify-center items-center p-8 text-primary"}>
                        <div><Calendar size={'150'}/></div>
                        <Link to={'https://calendly.com/support-pdzf/intro-to-adrsta-ai-for-agency-growth-plan'}>
                            <div className={'mt-8 text-3xl text-center underline hover:cursor-pointer hover:text-black'}
                                 style={{lineHeight: '48px'}}>Schedule
                                your initial meeting
                                with the
                                Adrsta AI team
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={"footer italic my-8 mx-8 text-center sm:text-left pb-8"}>
                    <span className={"font-bold"}>Adrsta AI</span> offers Marketing Science and Business Engineering in
                    a box
                    through a mix of software and
                    services. Founded by veterans of Meta’s Marketing Science and Agency leadership team, Adrsta AI has
                    extensive expertise in building proprietary tech for agencies of all sizes, including the Big 6 like
                    OMG, IPG, WPP, large Indie agencies like Wpromote, Tinuiti, and smaller performance agencies serving
                    a
                    range of SMB clients.
                </div>
            </div>
        </>
    );
};

export default METAAGP;
