import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import TeamCard, { TeamProps } from "./components/TeamCard";

const images = [
    { src: "/images/meta.png", size: "medium" },
    { src: "/images/google.png", size: "medium" },
    { src: "/images/nbcUniversal.png", size: "medium" },
    { src: "/images/pwd.png", size: "medium" },
    { src: "/images/gc.png", size: "medium" },
    { src: "/images/amex.png", size: "medium" },
    { src: "/images/disney.png", size: "medium" },
    { src: "/images/american-express.png", size: "small" },
    { src: "/images/home-depot.jpg", size: "small" },
    { src: "/images/mu-sigma.jpeg", size: "medium" },
    { src: "/images/era.png", size: "smediummall" },
    { src: "/images/columbia-university.png", size: "small" },
    { src: "/images/harvard.png", size: "small" },
    { src: "/images/nit-trichi.png", size: "small" },
];


const teams: TeamProps[] = [
    {
        name: "Ved Prakash",
        position: "CEO",
        imgSrc: "/images/us1.jpg",
        linkedIn: "https://www.linkedin.com/in/ved-prakash-sheoran-2a774818",
        education: [
            "B.Tech - NIT Trichy",
            "MBA - Michigan State",
            "MPA - Columbia University"
        ],
        experience: [
            "Head of Marketing Science (Agencies) at Meta",
            "Manager at PwC",
            "Data Science lead at EXL, WNS, and Mu Sigma",
            "Columbia University, Michigan State, NIT Trichy"
        ],
        speciality: ["Ad-Tech", "Data Science", "Consulting"]
    },
    {
        name: "Chase Mohney",
        position: "Chief Revenue Officer",
        imgSrc: "/images/chase.jpg", // Note: Ensure the image source is correct
        linkedIn: "https://www.linkedin.com/in/chasemohney",
        education: ["BA - Harvard University"],
        experience: [
            "Agency Sales & Emerging Tech at Meta",
            "Cofounder at Trilogy Interactive",
            "Political Campaign Marketing at Blackrock",
            "Harvard University"

        ],
        speciality: [
            "Partnership & Sales",
            "Innovation",
            "Marketing"
        ]
    },
    // {
    //     name: "Chris Keswani",
    //     position: "Head of Business Development",
    //     imgSrc: "/images/chris.png",
    //     linkedIn: "https://www.linkedin.com/in/chriskeswani",
    //     education: ["BS Marketing, Boston College"],
    //     experience: [
    //         "Product Marketing Manager at Meta",
    //         "Marketing & Business Development at Forest Interactive",
    //         "Account Executive at multiple start-ups"
    //     ],
    //     speciality: ["Business Development", "Marketing", "Partnerships"]
    // },
    {
        name: "Jag Singh",
        position: "Senior Research Scientist",
        imgSrc: "/images/jag.jpg",
        linkedIn: "https://www.linkedin.com/in/jagmohansingh",
        education: ["BS Marketing, Boston College"],
        experience: [
            "Senior Research Scientist at Vehant",
            "Algorithm Developer at Applied Materials",
            "Engineer at Samsung",
            "PHD from Norwegian University of Science and Technology"
        ],
        speciality: ["Business Development", "Marketing", "Partnerships"]
    },
    {
        name: "Pratik Gurukar",
        position: "Lead Engineer",
        imgSrc: "/images/pratik.jpg", // Note: Ensure the image source is correct
        linkedIn: "https://www.linkedin.com/in/pratik-gurukar",
        education: ["B.Tech - Vishwakarma Institute of Technology"],
        experience: [
            "Founder at TradersOps",
            "Senior Developer at Jiraaf",
            "Engineer at Radix Health",
            "VIT Pune"
        ],
        speciality: [
            "Full Stack Development",
            "Agile & Lean Methodologies",
            "Leadership & Team Management"
        ]
    },
];
const advisors: TeamProps[] = [
    {
        name: "Beau Avril",
        position: "Advisor",
        imgSrc: "/images/beau.png",
        linkedIn: "https://www.linkedin.com/in/beauavril",
        education: ["BBA, Cornell University"],
        experience: [
            "Director of Product Marketing at Meta",
            "Director of Business Operations at Google",
            "Board director at IAB",
            "BBA, Cornell University"
        ],
        speciality: ["Strategy", "Product", "Partnerships"]
    },
    {
        name: "Aaron Radin",
        position: "Advisor",
        imgSrc: "/images/aaron.png",
        linkedIn: "https://www.linkedin.com/in/aaronradin",
        education: ["BA - New York University", "MBA - Columbia University"],
        experience: [
            "CEO at British Basketball League",
            "Board member in Brooklyn Community Services",
            "Director at Meta",
            "Senior leadership roles at Disney, CBS and NBCUniversal",
            "CEO & Founder at Toura",
            "New York University, Columbia University"
        ],
        speciality: ["Media", "Technology", "Consulting"]
    },

];

const Teams: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className={"text-center my-8 text-4xl font-extrabold text-primary"}>Adrsta AI TEAM</div>
            <div className={"text-center my-12 text-3xl font-bold text-secondary"}>
                Our team consists of experts and practitioners in AdTech, AI, and Privacy.
            </div>

            <div className="flex flex-wrap justify-center my-20 sm:mx-32 mx-8">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`${image.size === "medium" ? "w-44" : "w-32"} p-2 flex justify-center mr-8 mb-8`}
                    >
                        <img src={image.src} alt={`Brand ${index}`} className="object-contain w-full h-auto" />
                    </div>
                ))}
            </div>
            <div className={"text-center font-extrabold text-2xl text-gray-500 sm:mx-32 mx-8 leading-8"}
                style={{ lineHeight: '40px' }}>
                As practitioners of Ad-tech, AI, and Privacy, our founding team is brought together by a shared vision of bringing greater efficiency to advertising using data and AI.
            </div>
            <div className={"flex flex-wrap justify-center my-20 mx-32"}>
                {teams.map((team, index) => (
                    <div key={index} className="flex flex-col justify-center mt-12 ml:8 sm:ml-16"
                        style={{ width: '500px' }}>
                        <TeamCard name={team.name} imgSrc={team.imgSrc} position={team.position}
                            linkedIn={team.linkedIn} experience={team.experience} speciality={team.speciality}
                            education={team.education} />
                    </div>
                ))}
            </div>
            <div className={"text-center my-8 text-4xl font-extrabold text-primary"}>Adrsta AI Advisors</div>
            <div className={"flex flex-wrap justify-center my-20 mx-32"}>
                {advisors.map((team, index) => (
                    <div key={index} className="flex flex-col justify-center mt-12 ml:8 sm:ml-16"
                        style={{ width: '500px' }}>
                        <TeamCard name={team.name} imgSrc={team.imgSrc} position={team.position}
                            linkedIn={team.linkedIn} experience={team.experience} speciality={team.speciality}
                            education={team.education} />
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};

export default Teams;
