import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Values from "./values";
import Home from "./home";
import Solutions from "./solutions";
import UseCases from "./user-cases";
import Pricing from "./pricing";
import Teams from "./teams";
import Privacy from "./privacy";
import TermAndConditions from "./term-and-conditions";
import About from "./about";
import METAAGP from "./meta-agp";
import AdsPage from "./AdsPage";
import { useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";
import TermConditionsPFD from "./term-condition-pdf";

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="values" element={<Values />} />
      <Route path="landing-page" element={<AdsPage />} />
      <Route path="product" element={<Solutions />} />
      <Route path="use-cases" element={<UseCases />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="teams" element={<Teams />} />
      <Route path="about" element={<About />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="meta-agp" element={<METAAGP />} />
      <Route path="terms-and-condition" element={<TermAndConditions />} />
      <Route path="terms-condition-pdf" element={<TermConditionsPFD />} />
    </Routes>
  );
}

export default App;
