import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";

const Privacy: React.FC = () => {
    return (
        <>
            <Navbar/>
            <div className={"mx-32 my-32 flex flex-col items-center"}
                 style={{
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center',
                     backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('/images/logo.png')`,
                 }}
            >
                <div className={"text-secondary font-extrabold text-4xl mb-16"}>About Adrsta AI</div>
                <div className={"text-center text-gray-600 text-3xl"} style={{lineHeight: '48px'}}>Adrsta AI is a
                    pioneer in the
                    unified Martech and AdTech SaaS
                    industry.
                    The team is driven by the mission to bring efficiency and privacy.
                    Adrsta AI is building a next-generation marketing science platform with cutting-edge solutions
                    designed for the cookieless and privacy-first world
                    We partner with advertisers, agencies, and publishers to maximize Marketing ROI
                </div>
                <div className={"text-center text-gray-600 text-3xl mt-24"} style={{lineHeight: '48px'}}>
                    The team consists of experts in the areas of AI, Adtech, MarTech, Privacy, and SaaS and bring with
                    them years of experience building and working on industry-leading technologies.
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Privacy;
