import React, { useState } from "react";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";

const links = [
  { text: "Values", url: "/values" },
  { text: "Product", url: "/product" },
  { text: "Team", url: "/teams" },
];
const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = () => {
    window.open("https://calendly.com/support-pdzf", "_blank");
  };
  return (
    <header className="header-wrap header-5 bg-white border-b-2 border-gray-100">
      <Drawer open={isOpen} direction={"right"} style={{ width: "300px" }}>
        <div
          className={"flex flex-col items-start bg-secondary h-full py-4 pr-4"}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="ml-8 text-white text-4xl"
            onClick={() => toggleDrawer()}
          />
          <ul
            className={
              "flex flex-col items-start justify-between text-gray-600 mt-8 w-full overflow-hidden"
            }
          >
            {links.map((link, index) => (
              <li
                key={index}
                className={
                  "font-bold text-white text-xl ml-4 py-8 border-b-2 border-blue-600 w-full"
                }
              >
                <Link to={link.url}>{link.text}</Link>
              </li>
            ))}
          </ul>
          <div className={"ml-8 mt-4"}>
            <div className={"text-white my-4"}>support@adrsta.ai</div>
            <Button
              onClick={handleClick}
              children={
                <div className={"flex items-center"}>
                  <div>request demo</div>
                  <FontAwesomeIcon icon={faArrowTrendUp} className="ml-2" />
                </div>
              }
            ></Button>
          </div>
        </div>
      </Drawer>
      <div className="main-header-wraper">
        <div className="container-fluid">
          <div className={"flex justify-around items-center py-8 px-8 sm:px-8"}>
            <div className="header-logo">
              <div className="logo flex flex-col justify-center">
                <a href="/">
                  <div
                    className={
                      "font-extrabold text-4xl text-primary text-center"
                    }
                  >
                    Adrsta AI
                  </div>
                </a>
                <p className={"text-sm text-center text-secondary font-bold"}>
                  AI for Media Planning, Activation & Measurement
                </p>
              </div>
            </div>
            <div className={"block sm:hidden"}>
              <FontAwesomeIcon
                icon={faBars}
                className="ml-8 text-secondary text-4xl"
                onClick={() => toggleDrawer()}
              />
            </div>
            <div className="hidden sm:flex">
              <div className="flex flex-col justify-center">
                <div className="main-menu">
                  <ul className={"flex justify-between text-gray-600"}>
                    {links.map((link, index) => (
                      <li
                        key={index}
                        className={"font-bold ml-16 hover:text-primary"}
                      >
                        <Link to={link.url}>{link.text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex flex-col justify-center ml-8">
                <Button
                  onClick={handleClick}
                  children={
                    <div className={"flex items-center"}>
                      <div>request demo</div>
                      <FontAwesomeIcon icon={faArrowTrendUp} className="ml-2" />
                    </div>
                  }
                ></Button>
              </div>
              <div className="mobile-nav-bar d-block ml-3 ml-sm-4 d-xl-none">
                {/* Mobile menu content */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
