import React from 'react';
import Button from "./Button";

export interface PricingProps {
    planName: string;
    planDescription: string;
    planOneLiner: string;
    price: string;
    pricingSuffix?: string;
    everythingText: string;
    planFeatures: string[];
}

const PricingCard: React.FC<PricingProps> = ({
                                                 planName,
                                                 planDescription,
                                                 planOneLiner,
                                                 price,
                                                 pricingSuffix,
                                                 planFeatures,
                                                 everythingText
                                             }) => {
    return (
        <div style={{maxWidth: "350px"}}>
            <div className="border-2 border-gray-200 rounded-xl w-fit mt-4">
                <div className="border-b-2 border-gray-200 p-4 flex flex-col justify-between h-[430px]">
                    <div className="heading-container h-[100px]">
                        <div className="font-extrabold text-secondary text-2xl text-center">{planName}
                        </div>
                        <div className="text-gray-500 text-sm text-center mt-2">{planDescription}
                        </div>
                    </div>
                    <div>
                        <div className="">
                            <div className={"h-[100px]"}>
                                <div className="text-center text-sm">You get :
                                </div>
                                <div
                                    className="text-center text-sm text-secondary font-bold leading-8 mt-2">{planOneLiner}
                                </div>
                            </div>
                            <div className="mt-8 text-center font-extrabold text-xl text-gray-600">{price}
                                <span
                                    className="mt-2 ml-2 text-gray-500 text-sm font-normal text-center">{pricingSuffix}</span>
                            </div>
                            <div className="mt-2 text-gray-500 text-sm text-center">Pause or cancel
                                anytime
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <Button onClick={() => {
                                window.open('https://calendly.com/support-pdzf', '_blank');
                            }} children={"CONTACT US"}></Button>
                        </div>
                    </div>
                </div>
                <div className="p-8 h-full sm:min-h-[624px]">
                    <div className=" font-extrabold text-gray-600">What's
                        included:
                    </div>
                    <div
                        className={"bg-primary rounded-full px-2 text-center py-2 my-4 mb-8 text-secondary font-bold text-xs"}
                        style={{visibility: everythingText !== "" ? 'visible' : 'hidden'}}
                    >
                        {everythingText === "" ? "text" : everythingText}
                    </div>
                    <div className="list-container">
                        <ul>
                            {planFeatures.map((feature, index) => (
                                <li key={index} className={"flex mb-4 items-start text-sm"}>
                                    <img className={"mr-4 mt-1 w-[16px]"} src="/images/icon-check-circle.svg"
                                         alt=""/>{feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricingCard;
